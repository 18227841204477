import Clipboard from '@ryangjchandler/alpine-clipboard'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import './bootstrap'
import './datatable'
import './notification'
import './confirm'
import './image-modal'

import './infinite-search'
import './progress-bar'

import './repeater'

// import { dispatch } from "alpinejs/src/utils/dispatch";
import intersect from '@alpinejs/intersect'
import DateRangePicker from 'flowbite-datepicker/DateRangePicker'
import Highcharts from 'highcharts/highstock'
import Annotations from 'highcharts/modules/annotations'
import NoData from 'highcharts/modules/no-data-to-display'
import 'flowbite'

// eslint-disable-next-line antfu/no-import-dist
import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm'

window.csrfToken = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content')

NoData(Highcharts)
Annotations(Highcharts)

window.Highcharts = Highcharts
window.DateRangePicker = DateRangePicker

Alpine.plugin(intersect)
Alpine.plugin(
  Clipboard.configure({
    onCopy: () => {
      console.log('Copied!')
      window.dispatchEvent(
        new CustomEvent('notice', {
          detail: {
            type: 'success',
            title: 'Copied!',
            text: 'Copied to clipboard!',
          },
        }),
      )
    },
  }),
)

history.pushAndNotify = function (url) {
  history.pushState({}, '', url.toString())
  window.dispatchEvent(new Event('popstate'))
}

window.Pusher = Pusher
window.Echo = new Echo({
  broadcaster: 'pusher',
  ...__appConfig.pusher,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
})

window.Alpine = Alpine

window.addEventListener('alpine:init', () => {
  Alpine.store('notification', {
    notify: (type, title, text) => {
      window.dispatchEvent(new CustomEvent('notice', {
        detail: {
          type,
          title,
          text,
        },
      }))
    },
  })
})

Livewire.start()
